import React from 'react';
import Layout from '../components/layout/Layout';

const ThankYouPage = () => (
  <Layout>
    <h3>Thank you for your submission!</h3>
  </Layout>
);

export default ThankYouPage;
